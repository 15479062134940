import { Component, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { EventService } from '../../_services/event.service';
import { DateTime, Settings } from 'luxon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Event, PaginationEvent } from '../../_models/event.model';
import { EventDialogComponent } from '../../_dialogs/event-dialog/event-dialog.component';
import { SmallHeaderComponent } from '../../_helpers/small-header/small-header.component';
import localeDe from '@angular/common/locales/de';
import { NimbuscloudService } from '../../_services/nimbuscloud.service';

registerLocaleData(localeDe);

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [CommonModule, MatDialogModule, SmallHeaderComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de',
    },
  ],
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss',
})
export class EventsComponent {
  events: Event[] = [];

  constructor(
    private dialog: MatDialog,
    private eventService: EventService,
    private nimbuscloud: NimbuscloudService
  ) {
    Settings.defaultLocale = 'de';
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.events = [];
    this.eventService.getAll().subscribe({
      next: (events: PaginationEvent) => {
        this.nimbuscloud.getEvents().subscribe({
          next: (nimbuscloudEvents: Event[]) => {
            let tmpEvent = [];
            if (nimbuscloudEvents && nimbuscloudEvents.length > 0) {
              tmpEvent = events.rows.concat(nimbuscloudEvents);
            } else {
              tmpEvent = events.rows;
            }
            tmpEvent.forEach((event) => {
              this.repeatEvent(event);
            });
            this.removePastEvents();
          },
          error: (error) => {
            console.log(error);
            events.rows.forEach((event) => {
              this.repeatEvent(event);
            });
            this.removePastEvents();
          },
        });
      },
      error: (error) => {
        console.log(error);
        this.nimbuscloud.getEvents().subscribe({
          next: (nimbuscloudEvents: Event[]) => {
            const tmpEvent = nimbuscloudEvents ?? [];
            tmpEvent.forEach((event) => {
              this.repeatEvent(event);
            });
            this.removePastEvents();
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
    });
  }

  // Repeat event between event.startDate and event.recurringEnd daily, weekly, monthly or yearly
  repeatEvent(event: Event) {
    const newEvents: Event[] = [];
    if (
      event.isRecurring &&
      event.startDate &&
      event.endDate &&
      event.recurringEnd
    ) {
      const startDate = DateTime.fromJSDate(new Date(event.startDate));
      const endDate = DateTime.fromJSDate(new Date(event.endDate));
      const recurringEnd = DateTime.fromJSDate(new Date(event.recurringEnd));
      const recurringType = event.recurringType;
      let newStartDate = startDate;
      let newEndDate = endDate;
      let newEvent: Event;
      while (newStartDate < recurringEnd) {
        newEvent = {
          ...event,
          startDate: new Date(
            newStartDate.toISODate() ?? new Date().toISOString()
          ),
          endDate: new Date(newEndDate.toISODate() ?? new Date().toISOString()),
        };
        newEvents.push(newEvent);
        switch (recurringType) {
          case 'daily':
            newStartDate = newStartDate.plus({ days: 1 });
            newEndDate = newEndDate.plus({ days: 1 });
            break;
          case 'weekly':
            newStartDate = newStartDate.plus({ weeks: 1 });
            newEndDate = newEndDate.plus({ weeks: 1 });
            break;
          case 'monthly':
            newStartDate = newStartDate.plus({ months: 1 });
            newEndDate = newEndDate.plus({ months: 1 });
            break;
          case 'yearly':
            newStartDate = newStartDate.plus({ years: 1 });
            newEndDate = newEndDate.plus({ years: 1 });
            break;
        }
      }
      this.removeSkipDates(event, newEvents);
    } else {
      this.events.push(event);
    }
  }

  removeSkipDates(event: Event, newEvents: Event[]) {
    // Remove skipped dates
    const skipDates = event.skipDates;
    if (skipDates && skipDates.length > 0 && event.startDate) {
      skipDates.forEach((date) => {
        newEvents.forEach((event, index) => {
          if (event.startDate) {
            event.startDate = new Date(event.startDate.setHours(0, 0, 0, 0));
            date = new Date(date).toISOString();
            if (event.startDate.toISOString() === date) {
              newEvents.splice(index, 1);
            }
          }
        });
      });
    }
    this.events = this.events.concat(newEvents);
  }

  removePastEvents() {
    this.events.sort((a, b) => {
      if (a.startDate && b.startDate) {
        return (
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        );
      } else {
        return 0;
      }
    });
    this.events = this.events.filter((event) => {
      if (event.startDate) {
        return (
          DateTime.fromJSDate(new Date(event.startDate)) >= DateTime.local()
        );
      } else {
        return false;
      }
    });
  }

  openEvent(event: Event) {
    this.dialog.open(EventDialogComponent, { data: event });
  }
}
