<app-big-header
  [title]="title"
  [subtitle]="subtitle"
  [image]="page.image"
></app-big-header>
<div class="container">
  @if(!id && !typeId) {
  <div class="row">
    @if(page.subitems?.length > 0){ @for(item of page.subitems; track
    item.alias){
    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
      <a
        class="card"
        [href]="
          item.alias
            ? page.alias + '/' + item.alias
            : 'kurse/details/' + item.title + '/' + item.id + '/' + item.type
        "
      >
        <div class="card-img">
          @if(item.image){
          <div class="card-background">
            <img
              [ngSrc]="item.image"
              fill
              [alt]="
                page.title +
                ' - ' +
                item.title +
                ' - ADTV Tanzschule Seidl Amberg'
              "
            />
          </div>
          } @else{
          <div class="card-background">
            <img
              [priority]="true"
              [ngSrc]="'./../../../../assets/default_box.webp'"
              fill
              [alt]="
                page.title +
                ' - ' +
                item.title +
                ' - ADTV Tanzschule Seidl Amberg'
              "
            />
          </div>
          }
        </div>
        <div class="card-text">{{ item.title }}</div>
      </a>
    </div>
    } }
  </div>
  }
</div>
